import axios from "axios";

export default class SubcategoriasService {
  getAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "subcategory/all")
  }
  getDisponibles(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "subcategory/enabled")
  }
  getById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "subcategory/"+id)
  }
  createSubcategoria(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "subcategory",data)
  }
  deleteSubcategoria(id){
    const data={
      id
    }
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "subcategory",{data})
  }
  editSubcategoria(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "subcategory/edit",data)
  }
}
